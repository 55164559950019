import React, { useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import * as FeedbackService from "../../../../../../../services/feedback.service";
import getCookie from "../../../../../../../helpers/getCookie";
import { useToast } from "../../../../../../../contexts/ToastContext";

import styles from "./FeatureRequestModal.module.css";

interface FeatureRequestModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeatureRequestModal: React.FC<FeatureRequestModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [formData, setFormData] = useState({
    useCase: "",
    description: "",
  });

  const csrfToken = getCookie("csrftoken") ?? "";
  const { showSuccessToast, toastResponse } = useToast();

  const handleSendRequest = async () => {
    const responseData = await FeedbackService.featureRequest(
      csrfToken,
      formData.useCase,
      formData.description
    );

    toastResponse(responseData).then(() => {
      showSuccessToast(
        "Success",
        <p>Feature request was sent successfully!</p>
      );
    });

    handleClose();
  };

  const handleClose = () => {
    setFormData({
      useCase: "",
      description: "",
    });
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          Feature Request
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          Please type your feature request
        </p>,
      ]}
      centered
      width="50%"
      onOk={handleSendRequest}
      onCancel={handleClose}
      footer={[
        <Button
          key="back"
          className={styles.close_button}
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={formData.useCase.length < 5}
          onClick={handleSendRequest}
        >
          Send
        </Button>,
      ]}
    >
      <Form className={styles.form} layout="vertical">
        <Form.Item label="Use case" required>
          <Input.TextArea
            value={formData.useCase}
            name="useCase"
            autoSize={{ minRows: 2, maxRows: 3 }}
            allowClear
            placeholder="Type the case where you need to use your feature"
            onChange={(event) =>
              setFormData({
                ...formData,
                useCase: event?.target.value,
              })
            }
          />
        </Form.Item>

        <Form.Item label="Description">
          <Input.TextArea
            value={formData.description}
            name="description"
            autoSize={{ minRows: 3, maxRows: 5 }}
            allowClear
            placeholder="Type the description of the feature you need"
            onChange={(event) =>
              setFormData({
                ...formData,
                description: event?.target.value,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeatureRequestModal;
