const languagesOptions = [
  { label: "English", value: 0 },
  { label: "German", value: 1 },
  { label: "French", value: 2 },
  { label: "Spanish", value: 3 },
  { label: "Polish", value: 4 },
  { label: "Czech", value: 5 },
  { label: "Bulgarian", value: 6 },
  { label: "Japanese", value: 7 },
  { label: "Hungarian", value: 8 },
  { label: "Russian", value: 9 },
  { label: "Ukrainian", value: 10 },
  { label: "Greek", value: 11 },
  { label: "Turkish", value: 12 },
  { label: "Italian", value: 13 },
  { label: "Dutch", value: 14 },
  { label: "Portuguese", value: 15 },
  { label: "Swedish", value: 16 },
  { label: "Finnish", value: 17 },
  { label: "Danish", value: 18 },
  { label: "Norwegian", value: 19 },
  { label: "Slovak", value: 20 },
  { label: "Romanian", value: 21 },
  { label: "Croatian", value: 22 },
  { label: "Serbian", value: 23 },
  { label: "Slovenian", value: 24 },
  { label: "Lithuanian", value: 25 },
  { label: "Latvian", value: 26 },
  { label: "Estonian", value: 27 },
  { label: "Arabic", value: 28 },
  { label: "Hebrew", value: 29 },
  { label: "Chinese", value: 30 },
  { label: "Korean", value: 31 },
  { label: "Thai", value: 32 },
  { label: "Vietnamese", value: 33 },
  { label: "Indonesian", value: 34 },
  { label: "Bosnian", value: 35 },
  { label: "Macedonian", value: 36 },
];

export default languagesOptions;
