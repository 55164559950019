import { SummarizationTypes } from "./summarizationTypes.enum";

type DescriptionsMap = {
  [key in SummarizationTypes]: { title: string; info: string }[];
};

const descriptions: DescriptionsMap = {
  [SummarizationTypes.OneToOne]: [
    {
      title: "What is one-to-one summarization?",
      info: "This feature generates a separate summary for each document you upload, ensuring a one-to-one correspondence between your input and the resulting summary.",
    },
    {
      title: "How to use?",
      info: "Upload and select the files you want to summarize, then choose a summarization style or template for the system to follow when creating summaries. Each document will be processed independently, with the key information condensed and structured based on your selected style. For example, if you upload 30 files, you’ll receive 30 individual summaries, each tailored to the content of the corresponding file. You can also provide a custom prompt with specific summarization instructions, like “Highlight project milestones” or “Focus on financial outcomes.” Additionally, you can choose the output language or select two languages for bilingual summaries.",
    },
    {
      title: "When to use?",
      info: "This feature is ideal for creating concise, standalone summaries for documents such as reports, meeting notes, proposals, case studies, or research papers.",
    },
  ],
  [SummarizationTypes.MultipleToOne]: [
    {
      title: "What is multiple-to-one summarization?",
      info: "This feature reviews all selected files and combines key information from the most relevant ones to create a single, comprehensive summary document.",
    },
    {
      title: "How to use?",
      info: "Upload and select the files you want to summarize, then choose a summarization style or template for the final output. The system will analyze and evaluate the content, selecting the top five most relevant files, and condense their information into a cohesive summary. For instance, if you upload 30 files, the system will choose the five most pertinent ones and use them to generate the summary document. You can also provide a custom prompt with specific summarization instructions, like “Emphasize quarterly performance” or “Focus on strategic recommendations.” Additionally, you can choose the output language or select two languages if you need a bilingual summary.",
    },
    {
      title: "When to use?",
      info: "This feature is ideal for users who need a unified summary that combines insights from multiple sources, such as executive summaries, consolidated reports, strategic reviews, or multi-source case analyses.",
    },
  ],
};

export default descriptions;
