import React, {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
} from "react";
import { UploadedFile } from "../pages/ToolsPage/interfaces/uploadedFile.interface";
import { ToolTypes } from "../enums/toolTypes.enum";
import * as ToolsService from "../pages/ToolsPage/services/tools.service";
 
import MainContext from "./MainContext";

interface ToolsContextType {
  selectedFiles: UploadedFile[];
  setSelectedFiles: (
    callbackOrFiles:
      | ((prevState: UploadedFile[]) => UploadedFile[])
      | UploadedFile[]
  ) => void;
  selectedTools: ToolTypes[];
  setSelectedTools: (
    callbackOrFiles: ((prevState: ToolTypes[]) => ToolTypes[]) | ToolTypes[]
  ) => void;
  projectFiles: UploadedFile[];
  setProjectFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  getProjectFiles: (id: string | number) => void;
  isAnyFileProcessing: boolean;
  libraryFiles: UploadedFile[];
  setLibraryFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
}

const ToolsContext = createContext<ToolsContextType | undefined>(undefined);

interface ToolsProviderProps {
  children: ReactNode;
}

export const ToolsProvider: React.FC<ToolsProviderProps> = ({ children }) => {
  
  const mainContext = useContext(MainContext);
 
  const [libraryFiles, setLibraryFiles] = useState<UploadedFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedTools, setSelectedTools] = useState<ToolTypes[]>([]);
  const [projectFiles, setProjectFiles] = useState<UploadedFile[]>([]);
  const [isAnyFileProcessing, setIsAnyFileProcessing] = useState(
    projectFiles.some((file: UploadedFile) => file.is_processing)
  );

 
 
  useEffect(() => {
    setIsAnyFileProcessing(
      projectFiles.some((file: UploadedFile) => file.is_processing)
    );
  }, [projectFiles]);

  useEffect(() => {
    getProjectFiles(Number(mainContext?.projectSelected?.id));
  }, [mainContext?.projectSelected]);

  const getProjectFiles = async (projectId: string | number) => {
    const fetchedFiles = await ToolsService.fetchFiles(projectId);
    setProjectFiles(fetchedFiles);
  };

  return (
    <ToolsContext.Provider
      value={{
        selectedFiles,
        setSelectedFiles,
        selectedTools,
        setSelectedTools,
        projectFiles,
        setProjectFiles,
        getProjectFiles,
        isAnyFileProcessing,
        libraryFiles,
        setLibraryFiles,
      }}>
      {children}
    </ToolsContext.Provider>
  );
};

export default ToolsContext;
