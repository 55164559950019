import React from "react";
import { ToolResultProps } from "../../interfaces/toolResultProps";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Tooltip, type TableColumnsType } from "antd";

import styles from "./TransactionResults.module.css";
import { TableData } from "../../interfaces/tableData.interface";
import parseDateString from "../../../../helpers/parseDateString";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import {
  TransactionItem,
  ToolResult,
} from "../../interfaces/toolsResults.interface";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import formatDate from "../../../../helpers/formatDate";
import { TransactionExpandedData } from "./interfaces/transactionExpandedData.interface";

const TransactionResults: React.FC<
  ToolResultProps & {
    expandedRowKeys: number[];
    setExpandedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
  }
> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage,
}) => {
  const navigate = useNavigate();

  const handleExpand = (expanded: boolean, record: TableData) => {
    let newExpandedRowKeys = [...expandedRowKeys];
    if (!newExpandedRowKeys) {
      newExpandedRowKeys = [];
    }
    if (expanded) {
      newExpandedRowKeys.push(record.key as number);
    } else {
      newExpandedRowKeys = newExpandedRowKeys.filter(
        (key) => key !== record.key
      );
    }
    setExpandedRowKeys(newExpandedRowKeys);

    // Update URL with the expanded rows
    const params = new URLSearchParams(window.location.search);
    if (newExpandedRowKeys.length > 0) {
      params.set("expandedRowKeys", newExpandedRowKeys.join(","));
    } else {
      params.delete("expandedRowKeys");
    }
    navigate({ search: params.toString() }, { replace: true });
  };

  const columns: TableColumnsType<TableData> = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "80px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Transaction} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (
      result.results &&
      ((result.results[0].extracted_lines &&
        result.results[0].extracted_lines.length === 0) ||
        result.results[0].error.length !== 0)
    )
      return (
        <Tooltip title="Process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    return (
      <Tooltip title="Process was successful.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const expandedRowRender = (record: TableData) => {
    const expandedRowResult = allResults.find(
      (result) => result.id === record.key
    )?.results[0];

    const expandedTableColumns: TableColumnsType<TransactionExpandedData> = [
      {
        title: "Transaction ID",
        dataIndex: "transactionId",
        key: "transactionId",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.transactionId.localeCompare(b.transactionId),
        ...getColumnSearchProps("transactionId"),
      },
      {
        title: "GL Account",
        dataIndex: "glAccount",
        key: "glAccount",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.glAccount.localeCompare(b.glAccount),
        ...getColumnSearchProps("glAccount"),
      },
      {
        title: "Reason for matching",
        dataIndex: "reasoning",
        key: "reasoning",
        align: "center",
        width: "20%",
        sorter: (a, b) => a.reasoning.localeCompare(b.reasoning),
        ...getColumnSearchProps("reasoning"),
      },
      {
        title: "Vendor name",
        dataIndex: "vendorName",
        key: "vendorName",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
        ...getColumnSearchProps("vendorName"),
      },
      {
        title: "Vendor SAP Code",
        dataIndex: "vendorSapCode",
        key: "vendorSapCode",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.vendorSapCode.localeCompare(b.vendorSapCode),
        ...getColumnSearchProps("vendorSapCode"),
      },
      {
        title: "Gross value",
        dataIndex: "grossValue",
        key: "grossValue",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.grossValue.localeCompare(b.grossValue),
        ...getColumnSearchProps("grossValue"),
      },
      {
        title: "Net value",
        dataIndex: "netPrice",
        key: "netPrice",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.netPrice.localeCompare(b.netPrice),
        ...getColumnSearchProps("netPrice"),
      },
      {
        title: "Vat amount",
        dataIndex: "vatAmount",
        key: "vatAmount",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.vatAmount.localeCompare(b.vatAmount),
        ...getColumnSearchProps("vatAmount"),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.currency.localeCompare(b.currency),
        ...getColumnSearchProps("currency"),
      },
    ];

    const data = expandedRowResult?.extracted_lines.map(
      (line: TransactionItem) => ({
        key: line.invoice_id,
        transactionId: line.invoice_id,
        glAccount: line.gl_account,
        reasoning: line.gl_reasoning,
        vatAmount: line.vat_amount,
        vendorSapCode: line.vendor_sap_code,
        vendorName: line.vendor_name,
        grossValue: line.gross_value,
        netPrice: line.net_value,
        currency: line.gross_value_currency,
      })
    );

    return expandedRowResult ? (
      <>
        <p>
          Total value: {expandedRowResult.extracted_total.total_gross_value}{" "}
          {expandedRowResult.extracted_total.total_gross_value_currency}
        </p>
        <Table
          className={styles.expanded_table}
          bordered
          columns={expandedTableColumns}
          dataSource={data}
          pagination={false}
        />
      </>
    ) : (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          current: currentPage, // Add current page control
          onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={
          allResults &&
          allResults.map((result) => ({
            key: result.id,
            file: result.file,
            user: result.user,
            status: checkStatus(result),
            createdAt: formatDate(result.created_at),
          }))
        }
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default TransactionResults;
