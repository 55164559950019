import React, { useState } from "react";
import { Button, Modal, Input, Form, Radio, Upload, UploadFile } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import * as FeedbackService from "../../../../../../../services/feedback.service";
import getCookie from "../../../../../../../helpers/getCookie";
import { useToast } from "../../../../../../../contexts/ToastContext";

import styles from "./BugReportModal.module.css";

interface BugReportModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ReportForm {
  priority: string;
  description: string;
  stepsToReplicate: string;
  attachedFiles: UploadFile[];
}

const urgencyOptions = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

const acceptedFileTypes =
  ".mp4, .mov, .avi, .wmv, .flv, .webm, .mkv, .docx, .doc, .xlsx, .xls, .csv, .json, .txt, .pdf, .png, .jpg, .jpeg, .tiff, .tif";

const BugReportModal: React.FC<BugReportModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [formData, setFormData] = useState<ReportForm>({
    priority: "low",
    description: "",
    stepsToReplicate: "",
    attachedFiles: [],
  });

  const csrfToken = getCookie("csrftoken") ?? "";
  const { showSuccessToast, toastResponse } = useToast();

  const handleSendReport = async () => {
    const responseData = await FeedbackService.bugReport(
      csrfToken,
      formData.priority,
      formData.description,
      formData.stepsToReplicate,
      formData.attachedFiles
    );

    toastResponse(responseData).then(() => {
      showSuccessToast("Success", <p>Bug report was sent successfully!</p>);
    });

    handleClose();
  };

  const handleClose = () => {
    setFormData({
      priority: "low",
      description: "",
      stepsToReplicate: "",
      attachedFiles: [],
    });
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          Bug Report
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          Please type all information about the bug you discovered
        </p>,
      ]}
      centered
      width="50%"
      onOk={handleSendReport}
      onCancel={handleClose}
      footer={[
        <Button
          key="back"
          className={styles.close_button}
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={formData.description.length < 5}
          onClick={handleSendReport}
        >
          Send
        </Button>,
      ]}
    >
      <div className={styles.modal_content}>
        <Form className={styles.form} layout="vertical">
          <Form.Item label="Urgency">
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={formData.priority}
              options={urgencyOptions}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  priority: event?.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <Input.TextArea
              value={formData.description}
              name="description"
              autoSize={{ minRows: 3, maxRows: 5 }}
              allowClear
              placeholder="Type the description of the discovered bug"
              onChange={(event) =>
                setFormData({
                  ...formData,
                  description: event?.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item label="Steps to reproduce">
            <Input.TextArea
              value={formData.stepsToReplicate}
              name="stepsToReplicate"
              autoSize={{ minRows: 3, maxRows: 5 }}
              allowClear
              placeholder="Type the steps to reproduce the bug"
              onChange={(event) =>
                setFormData({
                  ...formData,
                  stepsToReplicate: event?.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item>
            <Upload.Dragger
              accept={acceptedFileTypes}
              maxCount={3}
              multiple
              fileList={formData.attachedFiles}
              onChange={({ fileList }) =>
                setFormData({ ...formData, attachedFiles: fileList })
              }
              onRemove={(deletedFile) => {
                setFormData({
                  ...formData,
                  attachedFiles: formData.attachedFiles.filter(
                    (file) => file.uid !== deletedFile.uid
                  ),
                });
                return false;
              }}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  if (onSuccess) onSuccess("ok");
                }, 0);
              }}
              beforeUpload={() => false}
              showUploadList={{ showRemoveIcon: true }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag up to 3 files to this area to upload
              </p>
              <p className="ant-upload-hint">
                Accepted file types: {acceptedFileTypes}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default BugReportModal;
