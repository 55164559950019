import React, { useState } from "react";
import { Button, Modal, Input } from "antd";
import * as FeedbackService from "../../../../../../../services/feedback.service";
import getCookie from "../../../../../../../helpers/getCookie";
import { useToast } from "../../../../../../../contexts/ToastContext";

import styles from "./FeedbackModal.module.css";

interface FeedbackModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, setIsOpen }) => {
  const [data, setData] = useState("");

  const csrfToken = getCookie("csrftoken") ?? "";
  const { showSuccessToast, toastResponse } = useToast();

  const handleSendFeedback = async () => {
    const responseData = await FeedbackService.appFeedback(csrfToken, data);

    toastResponse(responseData).then(() => {
      showSuccessToast(
        "Success",
        <p>Your feedback about Documentor was sent successfully!</p>
      );
    });

    handleClose();
  };

  const handleClose = () => {
    setData("");
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          App Feedback
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          Please type your general feedback
        </p>,
      ]}
      centered
      width="50%"
      onOk={handleSendFeedback}
      onCancel={handleClose}
      footer={[
        <Button
          key="back"
          className={styles.close_button}
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={data.length < 5}
          onClick={handleSendFeedback}
        >
          Send
        </Button>,
      ]}
    >
      <Input.TextArea
        className={styles.text_field}
        value={data}
        onChange={(e) => setData(e.target.value)}
        placeholder="Tell us about your experience and impressions of Documentor usage. Feel free and good luck."
        autoSize={{ minRows: 5, maxRows: 8 }}
      />
    </Modal>
  );
};

export default FeedbackModal;
