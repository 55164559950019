import { ResultTypes } from "../pages/ResultsPage/enums/resultTypes.enum";
import { ResultTypesTool } from "../pages/ResultsPage/helpers/toolResultTypes";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

export const appFeedback = async (csrfToken: string, comment: string) => {
  const responseData = fetch(`${backendUrl}/api/create_general_feedback/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ comment }),
  });

  return responseData;
};

export const toolFeedback = async (
  csrfToken: string,
  toolSelected: ResultTypes,
  evaluation: boolean,
  comment?: string
) => {
  const responseData = fetch(`${backendUrl}/api/create_tools_feedback/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      thumbs_up: evaluation,
      comment,
      tool: ResultTypesTool[toolSelected],
    }),
  });

  return responseData;
};

export const aiAgentFeedback = async (
  csrfToken: string,
  type: string,
  comment: string
) => {
  const responseData = fetch(`${backendUrl}/api/create_ai_agent_feedback/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ type, comment }),
  });

  return responseData;
};

export const bugReport = async (
  csrfToken: string,
  priority: string,
  comment: string,
  steps_to_replicate: string,
  attachedFiles: any[]
) => {
  const attachment_1 = attachedFiles[0]?.originFileObj ?? null;
  const attachment_2 = attachedFiles[1]?.originFileObj ?? null;
  const attachment_3 = attachedFiles[2]?.originFileObj ?? null;

  const formData = new FormData();
  formData.append("priority", priority);
  formData.append("steps_to_replicate", steps_to_replicate);
  formData.append("comment", comment);
  attachment_1 && formData.append("attachment_1", attachment_1);
  attachment_2 && formData.append("attachment_2", attachment_2);
  attachment_3 && formData.append("attachment_3", attachment_3);

  const responseData = fetch(`${backendUrl}/api/create_bug_reports_feedback/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const featureRequest = async (
  csrfToken: string,
  use_case: string,
  comment?: string
) => {
  const responseData = fetch(
    `${backendUrl}/api/create_feature_requests_feedback/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ use_case, comment }),
    }
  );

  return responseData;
};
