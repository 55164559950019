import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Radio, Select, Tooltip } from "antd";
import {
  ClearOutlined,
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { CgFileDocument } from "react-icons/cg";
import { ImInsertTemplate } from "react-icons/im";

import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import ToolBase from "../ToolBase/ToolBase";
import MainContext from "../../../../contexts/MainContext";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import filterOption from "../../../../helpers/selectFilterOption";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";

import { SummarizationTypes } from "./helpers/summarizationTypes.enum";
import { SummarizationForm } from "./interfaces/summarizationForm.interface";
import { SummarizationTemplate } from "./interfaces/summarizationTemplate.interface";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
import { useToast } from "../../../../contexts/ToastContext";

import sizeOptions from "./helpers/sizeOptions";
import languagesOptions from "../../../../helpers/languagesOptions";
import descriptions from "./helpers/summarizationTypesDescriptions";
import outputFormatOptions from "./helpers/outputFormatOptions";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import truncateFilesArrayPipe from "../../../../helpers/truncateFilesArrayPipe";

import styles from "./DocumentSummarizationTool.module.css";

const DocumentSummarizationTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [summarizationType, setSummarizationType] =
    useState<SummarizationTypes>(SummarizationTypes.OneToOne);

  const [disclamerButtonHovered, setDisclamerButtonHovered] = useState(false);
  const [isDisclamerVisible, setIsDisclamerVisible] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [template, setTemplate] = useState<SummarizationTemplate | undefined>(
    undefined
  );
  const [templates, setTemplates] = useState<Array<SummarizationTemplate>>([]);

  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [formData, setFormData] = useState<SummarizationForm>({
    summarizationSize: "medium",
    customPrompt: undefined,
    outputFormat: "text",
    language: "English",
    secondLanguage: "Spanish",
  });

  const [isBilingual, setIsBilingual] = useState(false);
  const [resultName, setResultName] = useState<string | undefined>(undefined);

  const getTemplate = async (template: SummarizationTemplate) => {
    setTemplate(template);
    if (!template) {
      setSummarizationType(SummarizationTypes.OneToOne);
      setFormData({
        summarizationSize: "medium",
        customPrompt: undefined,
        outputFormat: "text",
        language: "English",
        secondLanguage: "Spanish",
      });
      setIsBilingual(false);
      return;
    }

    const summarizationTemplate = await templatesContext?.fetchSummarization(
      template.value
    );
    if (!summarizationTemplate) {
      setSummarizationType(SummarizationTypes.OneToOne);
      setFormData({
        summarizationSize: "medium",
        customPrompt: undefined,
        outputFormat: "text",
        language: "English",
        secondLanguage: "Spanish",
      });
      setIsBilingual(false);
    } else {
      setSummarizationType(summarizationTemplate.type);
      setFormData({
        summarizationSize: summarizationTemplate.length_of_summary,
        customPrompt: summarizationTemplate.custom_prompt,
        outputFormat: summarizationTemplate.output_format,
        language: summarizationTemplate.first_language,
        secondLanguage: summarizationTemplate.second_language
          ? summarizationTemplate.second_language
          : "Spanish",
      });
      setIsBilingual(!!summarizationTemplate.second_language);
    }
  };

  const deleteTemplate = async (data: SummarizationTemplate) => {
    await templatesContext?.deleteSummarization(data.value);

    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const createTemplate = async (templateName: string) => {
    const newTemplateId = await templatesContext?.createSummarization({
      name: templateName,
      type: summarizationType,
      length_of_summary: formData.summarizationSize,
      custom_prompt: formData.customPrompt,
      output_format: formData.outputFormat,
      first_language: formData.language,
      second_language: isBilingual ? formData.secondLanguage : "",
    });
    setFetchingTemplates(true);
    const summarizationTemplates =
      await templatesContext?.fetchAllSummarizations();
    const parsedTemplates = summarizationTemplates ?? [];

    setTemplate({ value: newTemplateId as number, label: templateName });
    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setFetchingTemplates(false);
  };

  const updateTemplate = async (templateName: string) => {
    await templatesContext?.editSummarization(template!.value, {
      name: templateName,
      type: summarizationType,
      length_of_summary: formData.summarizationSize,
      custom_prompt: formData.customPrompt,
      output_format: formData.outputFormat,
      first_language: formData.language,
      second_language: isBilingual ? formData.secondLanguage : "",
    });
    setFetchingTemplates(true);
    const summarizationTemplates =
      await templatesContext?.fetchAllSummarizations();
    const parsedTemplates = summarizationTemplates ?? [];

    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setTemplate((oldTemplate) => ({
      value: oldTemplate!.value,
      label: templateName,
    }));
    setFetchingTemplates(false);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const summarizationTemplates =
        await templatesContext?.fetchAllSummarizations();
      const parsedTemplates = summarizationTemplates ?? [];

      setTemplates(
        parsedTemplates.map((template) => ({
          value: template.id as number,
          label: template.name,
        }))
      );
      setFetchingTemplates(false);
    };

    setFetchingTemplates(true);
    fetchTemplates();
  }, []);

  const renderResultNameField = () => (
    <Form.Item
      required
      label="Result name"
      className={styles.result_name_field}
    >
      <Input
        allowClear
        placeholder="Ex.: Declaration of Independence"
        value={resultName}
        onChange={(event) => setResultName(event.target.value)}
      />
    </Form.Item>
  );

  const subTitles = {
    [SummarizationTypes.OneToOne]:
      "Proceed by summarizing documents from these files:",
    [SummarizationTypes.MultipleToOne]: (
      <>
        {renderResultNameField()}
        <p>Proceed by summarizing documents from these files:</p>
      </>
    ),
  };

  const submitRequests = {
    [SummarizationTypes.OneToOne]: () =>
      ToolsService.summarizeDocumentsOneToOne(
        csrfToken,
        toolsContext?.selectedFiles.map((file) => file.id)!,
        formData.summarizationSize,
        formData.customPrompt ?? "",
        formData.outputFormat,
        isBilingual
          ? [formData.language, formData.secondLanguage]
          : [formData.language]
      ),
    [SummarizationTypes.MultipleToOne]: () =>
      ToolsService.summarizeDocumentsManyToOne(
        csrfToken,
        toolsContext?.selectedFiles.map((file) => file.id)!,
        formData.summarizationSize,
        formData.customPrompt ?? "",
        formData.outputFormat,
        resultName!,
        isBilingual
          ? [formData.language, formData.secondLanguage]
          : [formData.language]
      ),
  };

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<CgFileDocument />}
          title="Document summarization"
          template={template}
          getTemplate={getTemplate}
          fetchingTemplates={fetchingTemplates}
          templates={templates}
          deleteTemplate={deleteTemplate}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item required={true} label="Extraction type">
              <Radio.Group
                buttonStyle="solid"
                value={summarizationType}
                onChange={(event) => {
                  setSummarizationType(event.target.value);
                }}
              >
                <Radio.Button value={SummarizationTypes.OneToOne}>
                  One to One
                </Radio.Button>
                <Radio.Button value={SummarizationTypes.MultipleToOne}>
                  Multiple to One
                </Radio.Button>
              </Radio.Group>
              <Button
                className={styles.disclamer_button}
                onMouseEnter={() => setDisclamerButtonHovered(true)}
                onMouseLeave={() => setDisclamerButtonHovered(false)}
                onClick={() => setIsDisclamerVisible((prevState) => !prevState)}
                icon={
                  isDisclamerVisible ? (
                    <UpOutlined />
                  ) : disclamerButtonHovered ? (
                    <DownOutlined className={styles.disclamer_icon} />
                  ) : (
                    <InfoCircleOutlined className={styles.disclamer_icon} />
                  )
                }
              >
                How It Works
              </Button>
            </Form.Item>

            <div
              className={`${styles.disclamer_container} ${
                !isDisclamerVisible ? styles.hidden : ""
              }`}
            >
              <div className={styles.disclamer}>
                {descriptions[summarizationType].map(
                  ({ title, info }, index) => {
                    return (
                      <div key={index}>
                        <p className={styles.disclamer_title}>{title}</p>
                        <p className={styles.disclamer_info}>{info}</p>
                      </div>
                    );
                  }
                )}
              </div>
            </div>

            {isDisclamerVisible && <div className={styles.divider}></div>}

            <Form.Item label="Summarization size">
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the summarization size"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    summarizationSize: option.value,
                  })
                }
                value={formData.summarizationSize}
                options={sizeOptions}
              />
            </Form.Item>

            <Form.Item label="Custom prompt">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={5000}
                allowClear
                placeholder="Type custom prompt"
                value={formData.customPrompt}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    customPrompt: event.target.value,
                  })
                }
              />
            </Form.Item>

            <Form.Item label="Output format">
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the output format"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    outputFormat: option.value,
                  })
                }
                value={formData.outputFormat}
                options={outputFormatOptions}
              />
            </Form.Item>

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.divider}></div>

            <Checkbox
              className={styles.bilingual_checkbox}
              checked={isBilingual}
              onChange={() => setIsBilingual((prev) => !prev)}
            >
              Bilingual document
            </Checkbox>

            <Form.Item
              className={styles.language_input}
              label="Second language of the output"
            >
              <Select
                disabled={!isBilingual}
                showSearch
                filterOption={filterOption}
                placeholder="Select the second language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    secondLanguage: option.label,
                  })
                }
                value={formData.secondLanguage}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <Button
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (!formData.customPrompt && !isBilingual) || !!template
                }
                onClick={() => {
                  setFormData({
                    summarizationSize: "medium",
                    customPrompt: undefined,
                    outputFormat: "text",
                    language: "English",
                    secondLanguage: "Spanish",
                  });
                  setIsBilingual(false);
                }}
              >
                Clear All Fields
              </Button>

              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.length === 0
                    ? "Please select the files you want to summarize and complete all the required fields from the tool."
                    : ""
                }
              >
                <Button
                  type="primary"
                  size="middle"
                  icon={<CgFileDocument className={styles.button_icon} />}
                  disabled={toolsContext?.selectedFiles.length === 0}
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                >
                  Summarize Documents
                </Button>
              </Tooltip>

              <Button
                type="primary"
                ghost
                size="middle"
                icon={<ImInsertTemplate className={styles.button_icon} />}
                onClick={() => setShowCreateTemplateModal(true)}
              >
                {template ? "Update Template" : "Create Template"}
              </Button>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Summarize Documents"
        subTitle={subTitles[summarizationType]}
        icon={<CgFileDocument className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Configuration",
          body: [
            sizeOptions.find(
              (option) => option.value === formData.summarizationSize
            )?.label!,
            outputFormatOptions.find(
              (option) => option.value === formData.outputFormat
            )?.label!,
          ],
        }}
        onClose={() => {
          setShowSubmitModal(false);
          setResultName(undefined);
        }}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.DocumentSummarization)
          );

          const request = submitRequests[summarizationType];
          const responseData = await request();

          toastResponse<{ file_name: string }[] | { output_name: string }>(
            responseData
          ).then((result) => {
            showSuccessToast(
              "Success",
              <p>Document summarization process started successfully!</p>
            );
          });

          await mainContext?.fetchNotifications();
        }}
        isSubmitDisabled={
          !resultName && summarizationType !== SummarizationTypes.OneToOne
        }
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Document Summarization Template"
            : "New Document Summarization Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new document summarization template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default DocumentSummarizationTool;
