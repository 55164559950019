import { MenuProps } from "antd";
import React, { useContext, useState } from "react";
import { CiLogout } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineManageAccounts } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ChangePageModal from "../../../../components/Topbar/components/ChangePageModal/ChangePageModal";
import GeneralFeedback from "../../../../components/Topbar/components/GeneralFeedback/GeneralFeedback";
import Help from "../../../../components/Topbar/components/Help/Help";
import NotificationsBell from "../../../../components/Topbar/components/NotificationsBell/NotificationsBell";
import UserAvatar from "../../../../components/Topbar/components/UserAvatar/UserAvatar";
import UserAvatarInDropdownWithUsername from "../../../../components/Topbar/components/UserAvatar/components/UserAvatarInDropdownWithUsername/UserAvatarInDropdownWithUsername";
import AuthContext from "../../../../contexts/AuthContext";
import styles from "./Topbar.module.css";
import MainContext from "../../../../contexts/MainContext";
interface TopbarProps {
  warnBeforeLeaving: boolean;
  menuSelected: string;
  setMenuSelected: (s: string) => void;
}

const Topbar: React.FC<TopbarProps> = ({
  warnBeforeLeaving,
  menuSelected,
  setMenuSelected,
}) => {
  const authContext = useContext(AuthContext);
  const mainContext = useContext(MainContext);
  const [redirectingUrl, setRedirectingUrl] = useState("");
  const [showChangePageModal, setShowConfirmLeavePageModal] = useState(false);

  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      label: (
        <UserAvatarInDropdownWithUsername></UserAvatarInDropdownWithUsername>
      ),
      key: "0",
      disabled: true,
    },
    {
      type: "divider",
    },
    {
      label: (
        <p
          className={styles.list_item}
          onClick={() => {
            if (warnBeforeLeaving) {
              setShowConfirmLeavePageModal(true);
              setRedirectingUrl("/");
            } else {
              const params = new URLSearchParams(window.location.search);
              const team = params.get("teamId");
              if (team) {
                mainContext?.setTeamSelected(undefined);
                mainContext?.setProjectSelected(undefined);
                localStorage.setItem("lastPath", `/${team}/`);

                navigate(`/${team}/`);
              } else {
                navigate("/");
              }
            }
          }}
        >
          <MdOutlineManageAccounts /> Home
        </p>
      ),
      key: "1",
    },
    // {
    //   label: (
    //     <p className={styles.list_item}>
    //       <IoSettingsOutline /> Settings
    //     </p>
    //   ),
    //   key: "2",
    // },
    {
      type: "divider",
    },
    {
      label: (
        <p className={styles.list_item} onClick={authContext?.logout}>
          <CiLogout />
          Log out
        </p>
      ),
      key: "3",
    },
  ];

  return (
    <div className={styles.topbar}>
      <ul className={styles.navigation}>
        <li
          className={styles.navigation_item}
          onClick={() => {
            setMenuSelected("members");
          }}
        >
          <button
            className={`${styles.navigation_item_text} ${
              menuSelected === "members" ? styles.navigation_item_selected : ""
            }`}
          >
            Members
          </button>
        </li>
        <li
          className={styles.navigation_item}
          onClick={() => {
            setMenuSelected("tools");
          }}
        >
          <button
            className={`${styles.navigation_item_text} ${
              menuSelected === "tools" ? styles.navigation_item_selected : ""
            }`}
          >
            Tools
          </button>
        </li>
 
      </ul>

      <ul className={styles.navigation}>
        <li className={styles.navigation_item}>
          <GeneralFeedback />
        </li>
        <li className={styles.navigation_item}>
          <Help />
        </li>
        <li className={styles.navigation_item}>
          <NotificationsBell />
        </li>
        <li className={styles.navigation_item}>
          <UserAvatar items={items} />
        </li>
      </ul>
      <ChangePageModal
        onClose={() => {
          setShowConfirmLeavePageModal(false);
        }}
        url={redirectingUrl}
        showChangePageModal={showChangePageModal}
      ></ChangePageModal>
    </div>
  );
};

export default Topbar;
