import React, { useState } from "react";
import { Button, Modal, Input } from "antd";
import { MdOutlineFeedback } from "react-icons/md";
import * as FeedbackService from "../../../../services/feedback.service";
import getCookie from "../../../../helpers/getCookie";
import { useToast } from "../../../../contexts/ToastContext";

import styles from "./AIAgentFeedback.module.css";

const AIAgentFeedback: React.FC = () => {
  const [data, setData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const csrfToken = getCookie("csrftoken") ?? "";
  const { showSuccessToast, toastResponse } = useToast();

  const handleSendFeedback = async () => {
    const responseData = await FeedbackService.aiAgentFeedback(
      csrfToken,
      "ai_agent_chat",
      data
    );

    toastResponse(responseData).then(() => {
      showSuccessToast(
        "Success",
        <p>Your feedback about AI Agent was sent successfully!</p>
      );
    });

    handleClose();
  };

  const handleClose = () => {
    setData("");
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        icon={<MdOutlineFeedback />}
        type="primary"
      >
        Give us a feedback
      </Button>

      <Modal
        open={isModalOpen}
        title={[
          <p key="title" className={styles.title}>
            AI Agent Feedback
          </p>,
          <p key="subTitle" className={styles.subTitle}>
            Please type your feedback about AI Agent
          </p>,
        ]}
        centered
        width="50%"
        onOk={handleSendFeedback}
        onCancel={handleClose}
        footer={[
          <Button
            key="back"
            className={styles.close_button}
            onClick={handleClose}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className={styles.submit_button}
            disabled={data.length < 5}
            onClick={handleSendFeedback}
          >
            Send
          </Button>,
        ]}
      >
        <Input.TextArea
          className={styles.text_field}
          value={data}
          onChange={(e) => setData(e.target.value)}
          placeholder="Tell us about your experience and impressions of AI Agent usage. Feel free and good luck."
          autoSize={{ minRows: 5, maxRows: 8 }}
        />
      </Modal>
    </>
  );
};

export default AIAgentFeedback;
