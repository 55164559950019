import { ToolTypes } from "../../../enums/toolTypes.enum";
import { ResultTypes } from "../enums/resultTypes.enum";

type ToolResultTypesMap = {
  [key in ToolTypes]: ResultTypes | undefined;
};

export const ToolResultTypes: ToolResultTypesMap = {
  [ToolTypes.MetadataExtractor]: ResultTypes.Metadata,
  [ToolTypes.ObligationsExtractor]: ResultTypes.Obligations,
  [ToolTypes.RisksIdentifier]: ResultTypes.Risks,
  [ToolTypes.DocumentComparison]: ResultTypes.Comparison,
  [ToolTypes.DocumentDrafting]: ResultTypes.Drafting,
  [ToolTypes.Translator]: ResultTypes.Translation,
  [ToolTypes.DocumentSummarization]: ResultTypes.Summarization,
  [ToolTypes.DocumentStandardization]: undefined,
  [ToolTypes.TimelineAnalysis]: ResultTypes.Timeline,
  [ToolTypes.CustomTool]: undefined,
  [ToolTypes.TransactionReview]: ResultTypes.Transaction,
};

type ResultTypesToolMap = {
  [key in ResultTypes]: ToolTypes;
};

export const ResultTypesTool: ResultTypesToolMap = {
  [ResultTypes.Metadata]: ToolTypes.MetadataExtractor,
  [ResultTypes.Obligations]: ToolTypes.ObligationsExtractor,
  [ResultTypes.Risks]: ToolTypes.RisksIdentifier,
  [ResultTypes.Comparison]: ToolTypes.DocumentComparison,
  [ResultTypes.Drafting]: ToolTypes.DocumentDrafting,
  [ResultTypes.Translation]: ToolTypes.Translator,
  [ResultTypes.Summarization]: ToolTypes.DocumentSummarization,
  [ResultTypes.Timeline]: ToolTypes.TimelineAnalysis,
  [ResultTypes.Transaction]: ToolTypes.TransactionReview,
};
