import { UploadedFile } from "../interfaces/uploadedFile.interface";
import { UploadFile } from "antd/lib/upload/interface";
import { GetProp, UploadProps } from "antd";
import Project from "../../../data/Project";
import { v4 as uuidv4 } from "uuid";

const backendUrl = process.env.REACT_APP_API_URL ?? "";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const fetchFiles = async (projectId: string | number) => {
  if (!projectId) {
    return [];
  }
  if (
    projectId === "NaN" ||
    projectId === "undefined" ||
    Number.isNaN(projectId)
  ) {
    return [];
  }
  const response = await fetch(`${backendUrl}/api/files/${projectId}`, {
    method: "GET",
    credentials: "include",
  });
  const body = await response.json();
   const fetchedFiles = body.sort((a: UploadedFile, b: UploadedFile) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
  console.log("fetchedFiles", fetchedFiles);
  return fetchedFiles;
};

export const deleteFiles = async (
  csrfToken: string,
  ids: Array<string | number>
) => {
  await fetch(`${backendUrl}/api/delete_files/`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids }),
  });
};

export const createFiles = async (
  csrfToken: string,
  projectId: string | number,
  files: File[],
  isAnonymise: boolean,
  isHandwritten: boolean,
  isProcessingEnabled: boolean
): Promise<void> => {
  const maxConcurrentUploads = 2; // Adjust as needed

  // Map files to their modified names (replace slashes with hyphens)
  const filesWithModifiedNames = files.map((file) => {
    const relativePath = file.webkitRelativePath || file.name;
    const formattedName = relativePath
    return { file, formattedName };
  });
   // Queue to manage files with modified names
  const fileQueue = [...filesWithModifiedNames];
  const uploadPromises: Promise<void>[] = [];

  // Function to start the next upload
  const startNextUpload = async (): Promise<void> => {
    if (fileQueue.length === 0) return;
    
    // Dequeue the next file and its modified name
    const { file, formattedName } = fileQueue.shift() as { file: File, formattedName: string };
    console.log(file, formattedName);
    // Pass the modified name to uploadFileInChunks
    await uploadFileInChunks(
      file,
      formattedName, // Pass the modified file name
      csrfToken,
      projectId, // Use the provided project ID
      isAnonymise,
      isHandwritten,
      isProcessingEnabled
    );

    // Start the next upload
    await startNextUpload();
  };

  // Start initial uploads up to the concurrency limit
  for (let i = 0; i < maxConcurrentUploads; i++) {
    uploadPromises.push(startNextUpload());
  }

  // Wait for all uploads to complete
  await Promise.all(uploadPromises);
};
async function uploadFileInChunks(
  file: File,
  modifiedFileName: string, // Add parameter for modified file name
  csrfToken: string,
  projectId: string | number,
  isAnonymise: boolean,
  isHandwritten: boolean,
  isProcessingEnabled: boolean,
  onProgress?: (progress: number, file: File) => void // Optional progress callback
): Promise<void> {
  const chunkSize = 1024 * 1024; // 1MB per chunk
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileId = uuidv4(); // Unique identifier for the file

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append("project", projectId.toString());
    formData.append("isAnonymise", isAnonymise.toString());
    formData.append("isHandwritten", isHandwritten.toString());
    formData.append("isProcessingEnabled", isProcessingEnabled.toString());
    formData.append("chunk", chunk);
    formData.append("fileId", fileId);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    formData.append("fileName", modifiedFileName); // Use the modified file name here
    formData.append("fileSize", file.size.toString());

    await fetch(`${backendUrl}/api/upload_file_chunk/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    });

    // Update progress
    if (onProgress) {
      onProgress((chunkIndex + 1) / totalChunks, file);
    }
  }
}
export const extractMetadata = async (
  csrfToken: string,
  ids: Array<string | number>,
  metadata: Array<{
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    metadata: metadata,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/extract_metadata/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const extractMetadataManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  result_name: string,
  metadata: Array<{
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    result_name,
    metadata: metadata,
    language: language ?? "English",
  });
  const responseData = fetch(
    `${backendUrl}/api/extract_metadata_with_one_result/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body,
    }
  );

  return responseData;
};

export const extractObligations = async (
  csrfToken: string,
  ids: Array<string | number>,
  obligations: Array<{ name: string; description: string }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    obligations: obligations,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/extract_obligations/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const identifyRisks = async (
  csrfToken: string,
  ids: Array<string | number>,
  risks: Array<{
    risk: string;
    task: string;
    mitigation: string;
    situation: string;
    example: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    risks: risks,
    language: language ?? "English",
  });
  const responseData = fetch(`${backendUrl}/api/identify_risks/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const compareDocumentsPlaybook = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(`${backendUrl}/api/compare_documents_playbook/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const compareDocumentsContract = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(`${backendUrl}/api/compare_documents_contract/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const compareDocumentsCustomRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_custom_regulation/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsPredefinedRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  regulation: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_regulation", JSON.stringify(regulation));
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_predefined_regulation/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsCustomChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_custom_checklist/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const compareDocumentsPredefinedChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  checklist: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_checklist", JSON.stringify(checklist));
  formData.append("language", JSON.stringify(language ?? "English"));

  const responseData = fetch(
    `${backendUrl}/api/compare_documents_predefined_checklist/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
      },
      body: formData,
    }
  );

  return responseData;
};

export const analyzeTimeline = async (
  csrfToken: string,
  ids: Array<string | number>,
  userQuery: string,
  timeline: boolean,
  orderingCriteria: Array<string>,
  language: string,
  outputName: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    user_query: userQuery,
    timeline: timeline,
    ordering_criteria: orderingCriteria,
    language: language ?? "English",
    output_name: outputName,
  });

  const responseData = fetch(`${backendUrl}/api/analyze_timeline/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsCustomPromptBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  tone: string,
  creativity: number,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    custom_prompt,
    tone,
    creativity,
    output_name,
    language,
  });

  const responseData = fetch(`${backendUrl}/api/draft_custom_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsTemplateBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    template_id: template,
    custom_prompt,
    output_name,
    language,
  });

  const responseData = fetch(`${backendUrl}/api/draft_template_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    file_ids: ids,
    custom_prompt,
    template_id: template,
    output_name,
    languages,
  });

  const responseData = fetch(`${backendUrl}/api/draft_many_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const draftingDocumentsOneToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  common_files: Array<any>,
  custom_prompt: string,
  template: number | string,
  languages: Array<string> = ["English"]
) => {
  const formData = new FormData();
  ids.forEach((id) => {
    formData.append("file_ids", id.toString());
  });
  common_files.forEach((file) => {
    formData.append("common_files", file);
  });
  formData.append("custom_prompt", custom_prompt ?? "");
  formData.append("template_id", template.toString() ?? "");
  languages.forEach((language) => {
    formData.append("languages", language);
  });

  const responseData = fetch(`${backendUrl}/api/draft_one_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });

  return responseData;
};

export const translateText = async (
  csrfToken: string,
  ids: Array<string | number>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    language: language,
  });

  const responseData = fetch(`${backendUrl}/api/translate_text/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const extractTransactionData = async (
  csrfToken: string,
  ids: Array<string | number>,
  team: string
) => {
  const endpointMap: { [key: string]: string } = {
    "Polish Transfer Pricing": "extract_transaction_data_pl_tp",
    "Polish BPS": "extract_transaction_data_pl_bps",
    "Czech BPS": "extract_transaction_data_cz_bps",
  };

  const endpoint = endpointMap[team];

  const apiUrl = `${backendUrl}/api/${endpoint}/`;

  const body = JSON.stringify({
    file_ids: ids,
  });

  const responseData = await fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};

export const summarizeDocumentsManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  length_of_summary: string,
  custom_prompt: string,
  output_format: string,
  result_name: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    result_name,
    file_ids: ids,
    length_of_summary,
    custom_prompt,
    output_format,
    languages,
  });

  const responseData = fetch(
    `${backendUrl}/api/summarize_documents_with_one_result/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body,
    }
  );

  return responseData;
};

export const summarizeDocumentsOneToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  length_of_summary: string,
  custom_prompt: string,
  output_format: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    file_ids: ids,
    length_of_summary,
    custom_prompt,
    output_format,
    languages,
  });

  const responseData = fetch(`${backendUrl}/api/summarize_documents/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });

  return responseData;
};
