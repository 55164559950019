import React, { useState } from "react";
import { MdOutlineFeedback } from "react-icons/md";
import { Button, Form, Input, Modal, Space } from "antd";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { ResultTypes } from "../../enums/resultTypes.enum";
import ToolsNames from "./helpers/toolsNames";
import * as FeedbackService from "../../../../services/feedback.service";
import getCookie from "../../../../helpers/getCookie";
import { useToast } from "../../../../contexts/ToastContext";

import styles from "./ToolFeedback.module.css";

const ToolFeedback: React.FC<{ toolSelected: ResultTypes | undefined }> = ({
  toolSelected,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<{
    evaluation: boolean | undefined;
    comment: string;
  }>({
    evaluation: undefined,
    comment: "",
  });

  const csrfToken = getCookie("csrftoken") ?? "";
  const { showSuccessToast, toastResponse } = useToast();

  const handleSendFeedback = async () => {
    const responseData = await FeedbackService.toolFeedback(
      csrfToken,
      toolSelected!,
      formData.evaluation!,
      formData.comment
    );

    toastResponse(responseData).then(() => {
      showSuccessToast(
        "Success",
        <p>
          Your feedback about {ToolsNames[toolSelected!]} was sent successfully!
        </p>
      );
    });

    handleClose();
  };

  const handleClose = () => {
    setFormData({
      evaluation: undefined,
      comment: "",
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        icon={<MdOutlineFeedback />}
        type="primary"
      >
        Give us a feedback
      </Button>

      <Modal
        open={isModalOpen}
        title={[
          <p key="title" className={styles.title}>
            {ToolsNames[toolSelected!]} Feedback
          </p>,
          <p key="subTitle" className={styles.subTitle}>
            Give us your feedback about {ToolsNames[toolSelected!]}
          </p>,
        ]}
        centered
        width="50%"
        onOk={handleSendFeedback}
        onCancel={handleClose}
        footer={[
          <Button
            key="back"
            className={styles.close_button}
            onClick={handleClose}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className={styles.submit_button}
            disabled={
              formData.evaluation === undefined ||
              (formData.evaluation === false && formData.comment.length < 5)
            }
            onClick={handleSendFeedback}
          >
            Send
          </Button>,
        ]}
      >
        <Form className={styles.form} layout="vertical">
          <Form.Item label="Do you like it?" required>
            <Space>
              <Button
                type={formData.evaluation === true ? "primary" : "default"}
                icon={<LikeOutlined />}
                onClick={() => setFormData({ ...formData, evaluation: true })}
              />
              <Button
                type={formData.evaluation === false ? "primary" : "default"}
                icon={<DislikeOutlined />}
                onClick={() => setFormData({ ...formData, evaluation: false })}
              />
            </Space>
          </Form.Item>
          <Form.Item label="Comments" required={formData.evaluation === false}>
            <Input.TextArea
              value={formData.comment}
              name="comments"
              autoSize={{ minRows: 5, maxRows: 8 }}
              allowClear
              placeholder={`Tell us about your experience and impressions of ${
                ToolsNames[toolSelected!]
              } usage. Feel free and good luck.`}
              onChange={(e) =>
                setFormData({ ...formData, comment: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ToolFeedback;
