import { Select, Tooltip } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import DocumentComparisonInputs from "./components/DocumentComparisonInputs/DocumentComparisonInputs";
import DocumentDraftingInputs from "./components/DocumentDraftingInputs/DocumentDraftingInputs";
import MetadataExtractorInputs from "./components/MetadataExtractorInputs/MetadataExtractorInputs";
import ObligationsExtractorInputs from "./components/ObligationsExtractorInputs/ObligationsExtractorInputs";
import RisksIdentifierInputs from "./components/RisksIdentifierInputs/RisksIdentifierInputs";
import styles from "./Tools.module.css";
import { ToolTypes } from "../../../../enums/toolTypes.enum";

import TeamsContext from "../../../../contexts/TeamsContext";

const availableTools = {
  [ToolTypes.MetadataExtractor]: "Data Extractor",
  [ToolTypes.ObligationsExtractor]: "Obligations Extractor",
  [ToolTypes.RisksIdentifier]: "Risks Identifier",
  [ToolTypes.DocumentComparison]: "Document Comparison",
  [ToolTypes.DocumentDrafting]: "Document Drafting",
  [ToolTypes.DocumentSummarization]: "Document Summarization",
  [ToolTypes.DocumentStandardization]: "Document Standardization",
  [ToolTypes.TimelineAnalysis]: "Timeline Analysis",
  [ToolTypes.Translator]: "Translation Tool",
  [ToolTypes.TransactionReview]: "Transaction Review",
  [ToolTypes.CustomTool]: "Custom Tool",
};

interface ToolsProps {}

const Tools: React.FC<ToolsProps> = (props) => {
  const teamsContext = useContext(TeamsContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [toolSelected, setToolSelected] = useState<ToolTypes | undefined>(
    undefined
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const toolFromUrl = queryParams.get("tool") as ToolTypes | null;

    if (!teamsContext?.teamSelected?.tools) {
      return; // Wait until tools are loaded
    }

    if (toolFromUrl && teamsContext.teamSelected.tools.includes(toolFromUrl)) {
      if (toolSelected !== toolFromUrl) {
        setToolSelected(toolFromUrl);
      }
    } else if (!toolFromUrl && !toolSelected) {
      console.log(
        "setting tool to first tool",
        teamsContext.teamSelected.tools[0]
      );
      setToolSelected(teamsContext.teamSelected.tools[0]);
    }
  }, [location.search, teamsContext?.teamSelected?.tools]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const toolFromUrl = queryParams.get("tool") as ToolTypes | null;

    if (toolSelected && toolSelected !== toolFromUrl) {
      console.log("toolSelected", toolSelected);
      queryParams.set("tool", toolSelected);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [toolSelected, navigate, location.pathname, location.search]);

  const handleToolChange = (value: string) => {
    setToolSelected(value as ToolTypes);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const toolFromUrl = queryParams.get("tool") as ToolTypes | null;
    console.log("toolFromUrl ON LOAD", toolFromUrl);
    setTimeout(() => {
      if (
        toolFromUrl &&
        teamsContext?.teamSelected?.tools.includes(toolFromUrl)
      ) {
        setToolSelected(toolFromUrl);
      }
    }, 3000);
  }, []);

  const disabledFieldsCondition = (tool: ToolTypes) =>
    tool === ToolTypes.DocumentStandardization ||
    tool === ToolTypes.DocumentSummarization ||
    tool === ToolTypes.CustomTool ||
    tool === ToolTypes.TimelineAnalysis ||
    tool === ToolTypes.Translator ||
    tool === ToolTypes.TransactionReview;

  return (
    <div className={styles.content}>
      <div className={styles.tools_content}>
        <div className={styles.tools_title}>Tools</div>
        <div className={styles.tools_select}>
          <Select
            style={{ width: 210 }}
            value={toolSelected}
            onChange={handleToolChange}
            options={teamsContext?.teamSelected?.tools.map((tool) => ({
              label: (
                <Tooltip
                  title={
                    disabledFieldsCondition(tool)
                      ? "This field is not customized for edits yet."
                      : undefined
                  }
                  placement="right"
                >
                  {availableTools[tool]}
                </Tooltip>
              ),
              value: tool,
              disabled: disabledFieldsCondition(tool),
            }))}
          />
        </div>
      </div>
      <div className={styles.inputs_content}>
        {toolSelected === ToolTypes.MetadataExtractor ? (
          <MetadataExtractorInputs />
        ) : undefined}
        {toolSelected === ToolTypes.ObligationsExtractor ? (
          <ObligationsExtractorInputs />
        ) : undefined}
        {toolSelected === ToolTypes.RisksIdentifier ? (
          <RisksIdentifierInputs />
        ) : undefined}
        {toolSelected === ToolTypes.DocumentComparison ? (
          <DocumentComparisonInputs />
        ) : undefined}
        {toolSelected === ToolTypes.DocumentDrafting ? (
          <DocumentDraftingInputs />
        ) : undefined}
      </div>
    </div>
  );
};

export default Tools;
