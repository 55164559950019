import React, { useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { FaRegCommentDots } from "react-icons/fa";
import { MdOutlineFeedback, MdOutlineBugReport } from "react-icons/md";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import BugReportModal from "./BugReportModal/BugReportModal";
import FeatureRequestModal from "./FeatureRequestModal/FeatureRequestModal";

import styles from "./GeneralFeedback.module.css";

const GeneralFeedback: React.FC = () => {
  const [isFeedback, setIsFeedback] = useState(false);
  const [isBugReport, setIsBugReport] = useState(false);
  const [isFeatureRequest, setIsFeatureRequest] = useState(false);

  const items: MenuProps["items"] = [
    {
      label: (
        <p className={styles.list_item}>
          <FaRegCommentDots /> App Feedback
        </p>
      ),
      key: "0",
      onClick: () => setIsFeedback(true),
    },
    {
      label: (
        <p className={styles.list_item}>
          <MdOutlineBugReport /> Bug Report
        </p>
      ),
      key: "1",
      onClick: () => setIsBugReport(true),
    },
    {
      label: (
        <p className={styles.list_item}>
          <VscGitPullRequestGoToChanges /> Feature Request
        </p>
      ),
      key: "2",
      onClick: () => setIsFeatureRequest(true),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <MdOutlineFeedback style={{ width: 28, height: 28 }} />
      </Dropdown>
      <FeedbackModal isOpen={isFeedback} setIsOpen={setIsFeedback} />
      <BugReportModal isOpen={isBugReport} setIsOpen={setIsBugReport} />
      <FeatureRequestModal
        isOpen={isFeatureRequest}
        setIsOpen={setIsFeatureRequest}
      />
    </>
  );
};

export default GeneralFeedback;
