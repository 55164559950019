import { ResultTypes } from "../../../enums/resultTypes.enum";

type ToolsNamesMap = {
  [key in ResultTypes]: string;
};

const ToolsNames: ToolsNamesMap = {
  [ResultTypes.Metadata]: "Data extraction",
  [ResultTypes.Obligations]: "Obligations extraction",
  [ResultTypes.Risks]: "Risks identification",
  [ResultTypes.Comparison]: "Document comparison",
  [ResultTypes.Drafting]: "Document drafting",
  [ResultTypes.Translation]: "Translation",
  [ResultTypes.Summarization]: "Document summarization",
  [ResultTypes.Transaction]: "Transactions review",
  [ResultTypes.Timeline]: "Timeline analysis",
};

export default ToolsNames;
