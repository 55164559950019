import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MainContext from "../../../../contexts/MainContext";
import type { TableColumnsType } from "antd";
import { Tooltip, Table, Button } from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import parseDateString from "../../../../helpers/parseDateString";
import formatDate from "../../../../helpers/formatDate";
import {
  ToolResult,
  MetadataResult,
} from "../../interfaces/toolsResults.interface";
import { TableData } from "../../interfaces/tableData.interface";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import { MetadataExpandedData } from "./interfaces/metadataExpandedData.interface";
import { ToolResultProps } from "../../interfaces/toolResultProps";
import styles from "./MetadataResults.module.css";
import Markdown from "markdown-to-jsx";
const MetadataResults: React.FC<ToolResultProps> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage,
}) => {
  // console.log(expandedRowKeys);
  const mainContext = useContext(MainContext);

  const handleExpand = (expanded: boolean, record: TableData) => {
    let newExpandedRowKeys = [...expandedRowKeys];
    if (expanded) {
      newExpandedRowKeys.push(record.key as number);
    } else {
      newExpandedRowKeys = newExpandedRowKeys.filter(
        (key) => key !== record.key
      );
    }
    setExpandedRowKeys(newExpandedRowKeys);

    // Update URL with the expanded rows
    const params = new URLSearchParams(window.location.search);
    if (newExpandedRowKeys.length > 0) {
      params.set("expandedRowKeys", newExpandedRowKeys.join(","));
    } else {
      params.delete("expandedRowKeys");
    }
  };

  const columns: TableColumnsType<TableData> = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "220px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Metadata} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (result.results.length === 0)
      return (
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    for (
      let metadataResultIndex = 0;
      metadataResultIndex < result.results.length;
      metadataResultIndex++
    ) {
      const metadataResult = result.results[metadataResultIndex];
      if (!metadataResult.value || metadataResult.source.length === 0) {
        return (
          <Tooltip title="Not all the values were extracted.">
            <FaInfoCircle color="yellow" />
          </Tooltip>
        );
      }
    }
    return (
      <Tooltip title="All data was extracted.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const expandedRowRender = (record: TableData) => {
    const expandedTableColumns: TableColumnsType<MetadataExpandedData> = [
      {
        title: "Data",
        dataIndex: "metadata",
        key: "metadata",
        align: "center",
        width: "12%",
        sorter: (a, b) => a.metadata.localeCompare(b.metadata),
        ...getColumnSearchProps("metadata"),
      },
      {
        title: "Result",
        dataIndex: "result",
        key: "result",
        align: "center",
        width: "28%",
      },
      {
        title: "Output format",
        dataIndex: "outputFormat",
        key: "outputFormat",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.outputFormat.localeCompare(b.outputFormat),
        ...getColumnSearchProps("outputFormat"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "center",
        width: "50%",
      },
    ];

    const expandedRowResults = allResults.filter(
      (result) => result.id === record.key
    )[0].results;

    const fileName = allResults.filter((result) => result.id === record.key)[0]
      .file;

    const expandSource = async (
      result: MetadataResult,
      fileName: string | undefined
    ) => {
      mainContext?.setTextViewContent({
        text: undefined,
      });
      mainContext?.setIsTextViewVisible(true);

      var excerpt = Array.isArray(result.source)
        ? result.source.join("\n\n")
        : result.source;
      excerpt = excerpt ? excerpt : "";
      let encodedExcerpt = encodeURIComponent(excerpt.replaceAll("\n", " "));
      let textResponse = await mainContext?.get_doc_text(
        fileName ? fileName : "",
        encodedExcerpt
      );
      mainContext?.setTextViewContent({
        text: textResponse["text"],
        title: "",
        scrollToText: textResponse["highlight"],
      });
    };

    const data = expandedRowResults?.map((result: MetadataResult) => ({
      key: result.id,
      metadata: result.name,
      result: result.error ? (
        <Tooltip title="Internal server error occurred while extracting the data attribute.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.value ? (
        <div style={{ textAlign: result.output_format === 'Complex Question' ? 'left' : 'inherit' }}>
          <Markdown>{result.value}</Markdown>
        </div>
      ) : (
        <Tooltip title="Could not extract the data.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
      outputFormat: result.output_format,
      source: result.error ? (
        <Tooltip title="Internal server error occurred while extracting the source.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.source?.length !== 0 ? (
        <>
          <p className={styles.source_text}>
            {result.source?.join("\n\n").split('\n').map((line, i) => (
              <React.Fragment key={i}>
                <Markdown>{line}</Markdown>
                <br />
              </React.Fragment>
            ))}
            &nbsp;
            <Button
              onClick={() => expandSource(result, fileName)}
              size={"small"}
              shape="circle"
              icon={<MenuUnfoldOutlined />}
            />
          </p>
        </>
      ) : (
        <Tooltip title="Could not find the source.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
    }));

    return expandedRowResults!.length === 0 ? (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    ) : (
      <Table
        bordered
        columns={expandedTableColumns}
        dataSource={data}
        pagination={false}
      />
    );
  };
  const totalRecords = allResults.length;
  const pageSizeOptions =
    totalRecords > 100
      ? ["15", "50", "100", totalRecords.toString()]
      : ["15", "50", "100"];
  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          pageSizeOptions,
          showSizeChanger: true,
          // current: currentPage, // Add current page control
          // onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={allResults.map((result) => ({
          key: result.id,
          file: result.file,
          user: result.user,
          status: checkStatus(result),
          createdAt: formatDate(result.created_at),
        }))}
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys ? expandedRowKeys : [],
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default MetadataResults;
