import React from "react";
import { Button, Table, Tooltip, Upload } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DragHandler from "./components/DragHandler/DragHandler";
import DragableRow from "./components/DragableRow/DragableRow";
import { MetadataForm } from "../../interfaces/metadataForm.interface";

import styles from "./CustomMetadataTable.module.css";
declare global {
  interface Window {
    XLSX: any;
  }
}

interface CustomMetadataTableProps {
  data: {
    id: string | number;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }[];
  isEditing: boolean;
  editRowItem: (value: string) => void;
  deleteRowItem: (value: string) => void;
  setData: React.Dispatch<React.SetStateAction<MetadataForm>>;
}

const CustomMetadataTable: React.FC<CustomMetadataTableProps> = ({
  data,
  isEditing,
  editRowItem,
  deleteRowItem,
  setData,
}) => {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData((prevState): MetadataForm => {
        const activeIndex = prevState.customMetadata.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.customMetadata.findIndex(
          (record) => record.id === over?.id
        );
        return {
          ...prevState,
          customMetadata: arrayMove(
            prevState.customMetadata,
            activeIndex,
            overIndex
          ),
        };
      });
    }
  };
  const totalRecords = data.length;
  const pageSizeOptions =
    totalRecords > 18
      ? ["6", "12", "18", totalRecords.toString()]
      : ["6", "12", "18"];

  const handleExportExcel = () => {
    const exportData = data.map((item) => ({
      "Data Parameter": item.metadata_parameter,
      Description: item.description,
      "Data Type": item.metadata_type,
      "Output Format": item.output_format,
    }));

    const ws = window.XLSX.utils.json_to_sheet(exportData);
    const wb = window.XLSX.utils.book_new();
    window.XLSX.utils.book_append_sheet(wb, ws, "Data");
    window.XLSX.writeFile(wb, "data_export.xlsx");
  };

  const handleImportExcel = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = window.XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = window.XLSX.utils.sheet_to_json(worksheet);
      console.log(jsonData);
      const newMetadata = jsonData.map((row: any, index: number) => ({
        id: `imported-${Date.now()}-${index}`,
        metadata_parameter: row["Data Parameter"],
        description: row["Description"],
        metadata_type: row["Data Type"],
        output_format: row["Output Format"],
      }));

      setData((prevState) => ({
        ...prevState,
        customMetadata: [...prevState.customMetadata, ...newMetadata],
      }));
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  return (
    <>
      <div className={styles.table_actions}>
        <Button
          icon={<FileExcelOutlined />}
          onClick={handleExportExcel}
          disabled={data.length === 0}
        >
          Export
        </Button>
        <Upload
          accept=".xlsx,.xls"
          showUploadList={false}
          beforeUpload={(file) => handleImportExcel(file)}
        >
          <Button icon={<FileExcelOutlined />}>Import</Button>
        </Upload>
      </div>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={data.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            pagination={{
              defaultPageSize: 6,
              pageSizeOptions,
              showSizeChanger: true,
            }}
            locale={{
              emptyText: (
                <div className={styles.empty_state}>
                  Custom data has not been added.
                </div>
              ),
            }}
            size="small"
            components={{ body: { row: DragableRow } }}
            columns={[
              {
                key: "sort",
                align: "center",
                width: 80,
                render: () => <DragHandler />,
              },
              {
                title: "Custom data",
                dataIndex: "customMetadata",
                key: "customMetadata",
                width: "30%",
                ellipsis: { showTitle: false },
                render: (val: string) => (
                  <Tooltip placement="topLeft" title={val}>
                    {val}
                  </Tooltip>
                ),
              },
              {
                title: "Custom data description",
                dataIndex: "customMetadataDescription",
                key: "customMetadataDescription",
                width: "38%",
                ellipsis: { showTitle: false },
                render: (val: string) => (
                  <Tooltip placement="topLeft" title={val}>
                    {val}
                  </Tooltip>
                ),
              },
              {
                title: "Output Format",
                dataIndex: "outputFormat",
                key: "outputFormat",
                width: "22%",
                ellipsis: { showTitle: false },
                render: (val: string) => (
                  <Tooltip placement="topLeft" title={val}>
                    {val}
                  </Tooltip>
                ),
              },
              {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                align: "center",
                width: "10%",
                ellipsis: { showTitle: false },
                render: (val: string) => (
                  <div className={styles.action_buttons}>
                    <Button
                      disabled={isEditing}
                      type="text"
                      onClick={() => editRowItem(val)}
                      icon={<EditOutlined />}
                    />

                    <Button
                      disabled={isEditing}
                      type="text"
                      onClick={() => deleteRowItem(val)}
                      icon={<DeleteOutlined />}
                    />
                  </div>
                ),
              },
            ]}
            dataSource={data
              .map((customOption: any) => ({
                key: customOption.id,
                customMetadata: customOption.metadata_parameter,
                customMetadataDescription: customOption.description,
                outputFormat: `${customOption.metadata_type} ${
                  customOption.output_format
                    ? "(" + customOption.output_format + ")"
                    : ""
                }`,
                actions: `${customOption.id}`,
              }))
              .reverse()}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default CustomMetadataTable;
