import {
  ClearOutlined,
  PlusOutlined,
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { MdOutlineDataObject } from "react-icons/md";
import MainContext from "../../../../contexts/MainContext";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import ToolsContext from "../../../../contexts/ToolsContext";

import { ImInsertTemplate } from "react-icons/im";

import {
  Button as ButtonAntd,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Radio,
} from "antd";
import { Checkbox } from "antd";

import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import CustomMetadataTable from "./components/CustomMetadataTable/CustomMetadataTable";
import generateUUID from "../../../../helpers/generateUUID";
import outputFormats from "../../../../helpers/outputFormats";
import filterOption from "../../../../helpers/selectFilterOption";

import languagesOptions from "../../../../helpers/languagesOptions";

import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import ToolBase from "../ToolBase/ToolBase";
import MultilineCascader from "../../../../components/MultilineCascader";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
import descriptions from "./helpers/extractionTypesDescription";
import { ExtractionTypes } from "./enums/extractionTypes.enum";
import { useToast } from "../../../../contexts/ToastContext";
import {
  MetadataForm,
  CustomMetadata,
} from "./interfaces/metadataForm.interface";

import styles from "./MetadataExtractorTool.module.css";
import truncateFilesArrayPipe from "../../../../helpers/truncateFilesArrayPipe";

const MetadataExtractorTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [customMetadataInput, setCustomMetadataInput] = useState("");
  const [customMetadataInputError, setCustomMetadataInputError] =
    useState(false);

  const [customMetadataDescription, setCustomMetadataDescription] =
    useState("");

  const [customMetadataSelect, setCustomMetadataSelect] = useState<string[]>(
    []
  );
  const [customMetadataSelectError, setCustomMetadataSelectError] =
    useState(false);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [template, setTemplate] = useState<
    { value: number; label: string } | undefined
  >(undefined);
  const [templates, setTemplates] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [extractionType, setExtractionType] = useState<ExtractionTypes>(
    ExtractionTypes.OneToOne
  );
  const [disclamerButtonHovered, setDisclamerButtonHovered] = useState(false);
  const [isDisclamerVisible, setIsDisclamerVisible] = useState(false);
  const [resultName, setResultName] = useState<string | undefined>(undefined);
  const [usePredefinedDocuments, setUsePredefinedDocuments] = useState(false);

  const [formData, setFormData] = useState<MetadataForm>({
    documentType: undefined,
    metadata: [],
    customMetadata: [],
    language: "As is in the document",
  });

  const [isEditingCustomMetadata, setIsEditingCustomMetadata] = useState(false);
  const [customMetadataItemToEdit, setCustomMetadataItemToEdit] = useState<
    CustomMetadata | undefined
  >(undefined);

  const getTemplate = async (template: { value: number; label: string }) => {
    setTemplate(template);
    if (!template) {
      setFormData({
        documentType: undefined,
        metadata: [],
        customMetadata: [],
        language: "As is in the document",
      });
      return;
    }
    const metadataTemplate = await templatesContext?.fetchMetadata(
      template.value
    );
    if (!metadataTemplate) {
      setFormData({
        documentType: undefined,
        metadata: [],
        customMetadata: [],
        language: "As is in the document",
      });
    } else {
      setFormData({
        documentType: metadataTemplate.contract_type,
        metadata: metadataTemplate.metadata.map((metadata) => ({
          value: metadata.id,
          label: metadata.name,
          description: metadata.description,
          metadata_type: metadata.type,
          output_format: metadata.output_format,
        })),
        customMetadata: metadataTemplate.custom_metadata.map((metadata) => ({
          id: generateUUID(),
          metadata_parameter: metadata.metadata_parameter,
          description: metadata.description,
          metadata_type: metadata.metadata_type,
          output_format: metadata.output_format,
        })),
        language: metadataTemplate.language,
      });
      setUsePredefinedDocuments(metadataTemplate.metadata.length > 0);
    }
  };

  const deleteCustomMetadataItem = (id: string) => {
    setFormData({
      ...formData,
      customMetadata: formData.customMetadata.filter((item) => item.id !== id),
    });
  };

  const editCustomMetadataItem = (id: string) => {
    setIsEditingCustomMetadata(true);

    const itemToEdit = formData.customMetadata.filter(
      (item) => item.id === id
    )[0];

    setCustomMetadataItemToEdit(itemToEdit);

    setCustomMetadataInput(itemToEdit.metadata_parameter);
    setCustomMetadataDescription(itemToEdit.description);
    setCustomMetadataSelect([
      itemToEdit.metadata_type,
      itemToEdit.output_format ?? "",
    ]);
  };

  const cancelEdittingCustomMetadataItem = () => {
    setIsEditingCustomMetadata(false);

    setCustomMetadataInput("");
    setCustomMetadataInputError(false);
    setCustomMetadataDescription("");
    setCustomMetadataSelect([]);
    setCustomMetadataSelectError(false);
  };

  const deleteTemplate = async (data: { value: number; label: string }) => {
    await templatesContext?.deleteMetadata(data.value);
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const createTemplate = async (templateName: string) => {
    const newTemplateId = await templatesContext?.createMetadata({
      name: templateName,
      metadata: formData.metadata.map((metadata) => metadata.value),
      custom_metadata: formData.customMetadata.map((metadata) => ({
        metadata_parameter: metadata.metadata_parameter,
        description: metadata.description,
        metadata_type: metadata.metadata_type,
        output_format: metadata.output_format,
      })),
      language: formData.language,
    });
    setFetchingTemplates(true);
    const metadataTemplates = await templatesContext?.fetchAllMetadata();
    const parsedTemplates = metadataTemplates ?? [];

    setTemplate({ value: newTemplateId as number, label: templateName });
    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setFetchingTemplates(false);
  };

  const updateTemplate = async (templateName: string) => {
    await templatesContext?.editMetadata(template!.value, {
      name: templateName,
      metadata: formData.metadata.map((metadata) => metadata.value),
      custom_metadata: formData.customMetadata.map((metadata) => ({
        metadata_parameter: metadata.metadata_parameter,
        description: metadata.description,
        metadata_type: metadata.metadata_type,
        output_format: metadata.output_format,
      })),
      language: formData.language,
    });
    setFetchingTemplates(true);
    const metadataTemplates = await templatesContext?.fetchAllMetadata();
    const parsedTemplates = metadataTemplates ?? [];

    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.id as number,
        label: template.name,
      }))
    );
    setTemplate((oldTemplate) => ({
      value: oldTemplate!.value,
      label: templateName,
    }));
    setFetchingTemplates(false);
  };

  const renderResultNameField = () => (
    <Form.Item
      required
      label="Result name"
      className={styles.result_name_field}
    >
      <Input
        allowClear
        placeholder="Ex.: Aggregated Data Report"
        value={resultName}
        onChange={(event) => setResultName(event.target.value)}
      />
    </Form.Item>
  );

  const subTitles = {
    [ExtractionTypes.OneToOne]: "Proceed by extracting data from these files:",
    [ExtractionTypes.MultipleToOne]: (
      <>
        {renderResultNameField()}
        <p>Proceed by extracting data from these files:</p>
      </>
    ),
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const metadataTemplates = await templatesContext?.fetchAllMetadata();
      const parsedTemplates = metadataTemplates ?? [];

      setTemplates(
        parsedTemplates.map((template) => ({
          value: template.id as number,
          label: template.name,
        }))
      );
      setFetchingTemplates(false);
    };

    setFetchingTemplates(true);
    fetchTemplates();
  }, []);

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<MdOutlineDataObject />}
          title="Data extractor"
          template={template}
          getTemplate={getTemplate}
          fetchingTemplates={fetchingTemplates}
          templates={templates}
          deleteTemplate={deleteTemplate}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item required={true} label="Extraction type">
              <Radio.Group
                buttonStyle="solid"
                value={extractionType}
                onChange={(event) => {
                  setExtractionType(event.target.value);
                }}
              >
                <Radio.Button value={ExtractionTypes.OneToOne}>
                  One to One
                </Radio.Button>
                <Radio.Button value={ExtractionTypes.MultipleToOne}>
                  Multiple to One
                </Radio.Button>
              </Radio.Group>
              <ButtonAntd
                className={styles.disclamer_button}
                type="dashed"
                onMouseEnter={() => setDisclamerButtonHovered(true)}
                onMouseLeave={() => setDisclamerButtonHovered(false)}
                onClick={() => setIsDisclamerVisible((prevState) => !prevState)}
                icon={
                  isDisclamerVisible ? (
                    <UpOutlined />
                  ) : disclamerButtonHovered ? (
                    <DownOutlined className={styles.disclamer_icon} />
                  ) : (
                    <InfoCircleOutlined className={styles.disclamer_icon} />
                  )
                }
              >
                Disclamer
              </ButtonAntd>
            </Form.Item>

            <div
              className={`${styles.description} ${
                !isDisclamerVisible ? styles.hidden : ""
              }`}
            >
              {descriptions[extractionType]}
            </div>
            {isDisclamerVisible && <div className={styles.divider}></div>}
            <Form.Item>
              <Checkbox
                checked={usePredefinedDocuments}
                onChange={(e) => setUsePredefinedDocuments(e.target.checked)}
              >
                Use predefined documents
              </Checkbox>
            </Form.Item>

            {usePredefinedDocuments && (
              <div className={styles.groupped_items}>
                <Form.Item
                  label="Document type"
                  tooltip={
                    <p>
                      You can setup your own document types and fields. You can
                      do this by{" "}
                      <a
                        href={`/teams?menu=tools&tool=MetadataExtractor&teamId=${mainContext?.teamSelected?.id}`}
                      >
                        clicking here
                      </a>
                      .
                    </p>
                  }
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={filterOption}
                    placeholder="Select document type"
                    onChange={(value: string, option: any) =>
                      setFormData({
                        ...formData,
                        documentType: option
                          ? { id: option.value, name: option.label }
                          : undefined,
                        metadata: [],
                      })
                    }
                    value={formData.documentType?.name}
                    options={mainContext?.metadataContractTypes.map(
                      (metadataContractType) => ({
                        value: metadataContractType.id,
                        label: metadataContractType.name,
                      })
                    )}
                  />
                </Form.Item>

                <Form.Item label="Data to extract">
                  <Select
                    disabled={!formData.documentType}
                    mode="multiple"
                    size="middle"
                    filterOption={filterOption}
                    allowClear
                    showSearch
                    placeholder="Select data"
                    onChange={(value, option: any) =>
                      setFormData({
                        ...formData,
                        metadata: option ? option : [],
                      })
                    }
                    value={formData.metadata}
                    options={mainContext?.metadataContractTypes
                      .filter(
                        (metadataContractType) =>
                          metadataContractType.id === formData.documentType?.id
                      )[0]
                      ?.metadata_types.map((metadata) => ({
                        value: metadata.id,
                        label: metadata.name,
                        description: metadata.description,
                        metadata_type: metadata.type,
                        output_format: metadata.output_format,
                      }))}
                    optionRender={(option) => (
                      <Space>
                        <Tooltip
                          overlayClassName={styles.description_tooltip}
                          title={
                            <>
                              <p className={styles.description_content}>
                                {option.data.description}
                              </p>
                              <p>
                                <span className={styles.italic_text}>
                                  Type:{" "}
                                </span>
                                {option.data.metadata_type}
                              </p>
                              {option.data.output_format && (
                                <p>
                                  <span className={styles.italic_text}>
                                    Output format:{" "}
                                  </span>
                                  {option.data.output_format}
                                </p>
                              )}
                            </>
                          }
                        >
                          <span>{option.label}</span>
                        </Tooltip>
                      </Space>
                    )}
                  />
                </Form.Item>
              </div>
            )}

            <div className={styles.groupped_items}>
              <Form.Item label="Custom data">
                <div className={styles.custom_metadata_entry}>
                  <Form.Item
                    validateStatus={
                      customMetadataInputError ? "error" : undefined
                    }
                    style={{ width: "30%" }}
                  >
                    <Input
                      allowClear
                      placeholder="Add custom data"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCustomMetadataInput(event.target.value)
                      }
                      value={customMetadataInput}
                    />
                  </Form.Item>

                  <Form.Item style={{ width: "50%" }}>
                    <Input.TextArea
                      rows={2}
                      allowClear
                      placeholder="Add custom data description"
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement>
                      ) => setCustomMetadataDescription(event.target.value)}
                      value={customMetadataDescription}
                    />
                  </Form.Item>

                  <Form.Item
                    validateStatus={
                      customMetadataSelectError ? "error" : undefined
                    }
                    style={{ width: "20%" }}
                  >
                    <MultilineCascader
                      placeholder="Select output format"
                      value={customMetadataSelect}
                      onChange={(value: any) => setCustomMetadataSelect(value)}
                      options={outputFormats}
                    />
                  </Form.Item>
                  <Form.Item>
                    {isEditingCustomMetadata ? (
                      <div className={styles.editing_buttons}>
                        <ButtonAntd
                          type="primary"
                          icon={<CheckOutlined />}
                          onClick={() => {
                            if (
                              !customMetadataInput ||
                              customMetadataInput.length > 70
                            )
                              setCustomMetadataInputError(true);

                            if (
                              !customMetadataSelect ||
                              customMetadataSelect.length === 0
                            )
                              setCustomMetadataSelectError(true);

                            if (
                              !customMetadataInput ||
                              !customMetadataSelect ||
                              customMetadataSelect.length === 0
                            )
                              return;

                            setFormData({
                              ...formData,
                              customMetadata: formData.customMetadata.map(
                                (item) =>
                                  item.id === customMetadataItemToEdit?.id
                                    ? {
                                        ...item,
                                        metadata_parameter: customMetadataInput,
                                        description:
                                          customMetadataDescription ?? "",
                                        metadata_type: customMetadataSelect[0],
                                        output_format:
                                          customMetadataSelect[1] ?? "",
                                      }
                                    : item
                              ),
                            });

                            cancelEdittingCustomMetadataItem();
                          }}
                        />
                        <ButtonAntd
                          type="primary"
                          icon={<CloseOutlined />}
                          onClick={cancelEdittingCustomMetadataItem}
                        />
                      </div>
                    ) : (
                      <ButtonAntd
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (
                            !customMetadataInput ||
                            customMetadataInput.length > 70
                          )
                            setCustomMetadataInputError(true);

                          if (
                            !customMetadataSelect ||
                            customMetadataSelect.length === 0
                          )
                            setCustomMetadataSelectError(true);

                          if (
                            !customMetadataInput ||
                            !customMetadataSelect ||
                            customMetadataSelect.length === 0
                          )
                            return;
                          setFormData({
                            ...formData,
                            customMetadata: [
                              ...formData.customMetadata,
                              {
                                id: generateUUID(),
                                metadata_parameter: customMetadataInput,
                                description: customMetadataDescription ?? "",
                                metadata_type: customMetadataSelect[0],
                                output_format: customMetadataSelect[1] ?? "",
                              },
                            ],
                          });

                          setCustomMetadataInput("");
                          setCustomMetadataInputError(false);
                          setCustomMetadataDescription("");
                          setCustomMetadataSelect([]);
                          setCustomMetadataSelectError(false);
                        }}
                      />
                    )}
                  </Form.Item>
                </div>

                <CustomMetadataTable
                  data={formData.customMetadata}
                  isEditing={isEditingCustomMetadata}
                  editRowItem={editCustomMetadataItem}
                  deleteRowItem={deleteCustomMetadataItem}
                  setData={setFormData}
                />
              </Form.Item>
            </div>

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={[
                  { label: "As is in the document", value: "-1" },
                  ...languagesOptions,
                ]}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (!formData.documentType &&
                    formData.metadata.length === 0 &&
                    formData.customMetadata.length === 0) ||
                  !!template
                }
                onClick={() =>
                  setFormData({
                    documentType: undefined,
                    metadata: [],
                    customMetadata: [],
                    language: "English",
                  })
                }
              >
                Clear All Fields
              </ButtonAntd>

              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.length === 0 ||
                  (formData.metadata.length === 0 &&
                    formData.customMetadata.length === 0) ||
                  !formData.language
                    ? "Please select the files you want to work with and the data you want to extract."
                    : ""
                }
              >
                <ButtonAntd
                  type="primary"
                  size="middle"
                  icon={<MdOutlineDataObject className={styles.button_icon} />}
                  disabled={
                    toolsContext?.selectedFiles.length === 0 ||
                    (formData.metadata.length === 0 &&
                      formData.customMetadata.length === 0) ||
                    !formData.language
                  }
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                >
                  Extract Data
                </ButtonAntd>
              </Tooltip>

              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ImInsertTemplate className={styles.button_icon} />}
                disabled={
                  (formData.metadata.length === 0 &&
                    formData.customMetadata.length === 0) ||
                  !formData.language
                }
                onClick={() => setShowCreateTemplateModal(true)}
              >
                {template ? "Update Template" : "Create Template"}
              </ButtonAntd>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Extract Data"
        subTitle={subTitles[extractionType]}
        icon={<MdOutlineDataObject className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Data",
          body: [
            ...formData.metadata.map((item) => item.label),
            ...formData.customMetadata.map((item) => item.metadata_parameter),
          ],
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.MetadataExtractor)
          );

          if (extractionType === "OneToOne") {
            const responseData = await ToolsService.extractMetadata(
              csrfToken,
              toolsContext?.selectedFiles.map((file) => file.id)!,
              [
                ...formData.metadata.map((item) => ({
                  metadata_parameter: item.label,
                  description: item.description,
                  metadata_type: item.metadata_type,
                  output_format: item.output_format,
                })),

                ...formData.customMetadata.map((item) => ({
                  metadata_parameter: item.metadata_parameter,
                  description: item.description,
                  metadata_type: item.metadata_type.toLowerCase(),
                  output_format: item.output_format,
                })),
              ],
              formData.language
            );

            toastResponse<{ file_name: string }[]>(responseData).then(
              (result) => {
                showSuccessToast(
                  "Success",
                  <p>Data extraction process started successfully!</p>
                );
              }
            );
          } else {
            const responseData = await ToolsService.extractMetadataManyToOne(
              csrfToken,
              toolsContext?.selectedFiles.map((file) => file.id)!,
              resultName!,
              [
                ...formData.metadata.map((item) => ({
                  metadata_parameter: item.label,
                  description: item.description,
                  metadata_type: item.metadata_type,
                  output_format: item.output_format,
                })),

                ...formData.customMetadata.map((item) => ({
                  metadata_parameter: item.metadata_parameter,
                  description: item.description,
                  metadata_type: item.metadata_type.toLowerCase(),
                  output_format: item.output_format,
                })),
              ],
              formData.language
            );

            toastResponse<{ file_name: string }[]>(responseData).then(
              (result) => {
                showSuccessToast(
                  "Success",
                  <p>
                    Data extraction{" "}
                    <span className={styles.bold_italic_text}>
                      {truncateFilesArrayPipe(
                        result.map(({ file_name }) => file_name)
                      )}{" "}
                    </span>
                    was completed successfully!
                  </p>
                );
              }
            );
          }

          await mainContext?.fetchNotifications();
        }}
        isSubmitDisabled={
          extractionType === ExtractionTypes.MultipleToOne &&
          (!resultName || resultName!.length > 250)
        }
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Data Extractor Template"
            : "New Data Extractor Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new data extractor template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default MetadataExtractorTool;
